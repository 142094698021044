#footerContainer {
  z-index: 20;
  min-height: 30px;
  max-height: 30px;
  text-align: right;
  padding-right: 8px;
  box-shadow: 0 15px 10px 10px rgba(94, 94, 94, 0.5);
}

#footerItem {
  padding-left: 12px;
  padding-right: 12px;
  font-size: 0.8rem;
}
