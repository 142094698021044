.uninow-content-container {
  width: 100%;
  min-width: 250px;
  max-width: 750px;
  padding: 20px;
  margin: auto;
  margin-bottom: 60px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 2px 22px 0 rgba(196, 196, 196, 0.5);
}

.uninow-content-container .headline {
  margin-left: -12.5px;
  margin-right: -12.5px;
  padding: 12.5px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 24px;
}

.uninow-content-container .ant-form-item-label > label {
  font-size: 0.9rem;
  font-weight: 500;
  color: #82828c;
}

.uninow-content-container .ant-form-extra,
.uninow-content-container .ant-form-explain {
  font-size: 12px;
}

.uninow-content-container .ant-form-extra {
  color: #82828c;
  margin-bottom: 12px;
}

.uninow-content-container .ant-btn-primary {
  font-weight: 700;
  padding: 0px 30px;
  border-width: 0;
  border-radius: 100px;
}

.uninow-content-container .uninow-geosuggest > .ant-form-item-control-wrapper > .ant-form-item-control > span::after {
  content: '' !important;
}
