h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
  margin: 0;
}

h1 {
  font-size: 2.6rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.38rem;
}

.color-primary {
  color: #f21850;
}

.color-secondary {
  color: #82828c;
}

.clickable-primary {
  color: #f21850;
  cursor: pointer;
}

.clickable-primary:hover {
  opacity: 0.8;
}

.clickable-secondary {
  color: #82828c;
  cursor: pointer;
}

.clickable-secondary:hover {
  color: #f21850;
}

.weight-light {
  font-weight: 200;
}

.weight-regular {
  font-weight: 400;
}

.weight-medium {
  font-weight: 500;
}

.weight-bold {
  font-weight: 700;
}

.size-tiny {
  font-size: 0.75rem;
}

.size-small {
  font-size: 0.9rem;
}

.size-large {
  font-size: 1.1rem;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.horizontal-divider {
  height: 1px;
  background-color: #d8d8d8;
  margin: 20px 0px;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  color: #202533;
  overflow-x: hidden;
  background-color: #edeff4;
}

* {
  min-height: 0;
  min-width: 0;
}

@media (max-width: 576px) {
  html {
    font-size: 14px;
  }
}

::-webkit-scrollbar {
  display: none;
}
